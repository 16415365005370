import React, {useContext, useState} from "react";
import {Alert, Box, CircularProgress, Container, Fab, Grid, Paper} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {fetchSuppliers, getSuppliers} from "../../../api/supplierApi";
import {useTableLang} from "../../../utils/tableLang";
import {LanguageContext} from "../../../components/LanguageContext";
import CachedIcon from "@mui/icons-material/Cached";

const SupplierPage = () => {
    const tableLang = useTableLang();
    const {translations} = useContext(LanguageContext) || {};
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data, isLoading} = useQuery({
        queryKey: ["suppliers"],
        queryFn: () => getSuppliers(),
    });

    const mutation = useMutation({
        mutationFn: () => fetchSuppliers(),
        onSuccess: (data) => {
            setError("");
            setSuccess(translations?.adminSupplierPageSuccess || data.data.message);
            setLoading(false);
            queryClient.invalidateQueries(["suppliers"]);
        },
        onError: (error) => {
            setSuccess("");
            setLoading(false);
            setError(translations?.adminSupplierPageError || error.data.message);
        },
    });

    const fetchSuppliersHandle = () => {
        try {
            setLoading(true);
            mutation.mutateAsync().catch((e) => {
            });
        } catch (e) {
        }
    };
    const columns = [
        {
            field: "id",
            headerName: translations.adminSupplierPageID,
            width: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "code",
            headerName: translations.adminSupplierPageCode,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations.adminSupplierPageName,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "gln",
            headerName: translations.adminSupplierPageGLN,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <span>{params.row.gln == null ? "-----" : params.row.gln}</span>
            )
        },
        {
            field: "min_value",
            headerName: translations.adminSupplierPageMinValue,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <span>{params.row.min_value == null ? "-----" : params.row.min_value}</span>
            )
        },
        {
            field: "automatic_ordering",
            headerName: translations.automaticOrdering,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <span>{params.row.automatic_ordering == null ? "-----" : params.row.automatic_ordering ? translations.tableLangBooleanCellTrueLabel : translations.tableLangBooleanCellFalseLabel}</span>
            )
        },
        {
            field: "frequency",
            headerName: translations.frequency,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <span>{params.row.frequency == null ? "-----" : params.row.frequency}</span>
            )
        },
    ];
    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            {success === "" ? null : (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}
            <Box sx={{"& > :not(style)": {m: 1, position: "fixed", right: 20}}}>
                <Fab onClick={() => fetchSuppliersHandle()} color="primary" aria-label="refresh">
                    {loading ? <CircularProgress color="secondary"/> : <CachedIcon/>}
                </Fab>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column", height: 600}}>
                        {isLoading ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}
                            >
                                <CircularProgress/>
                            </div>
                        ) : (
                            <DataGrid
                                slots={{toolbar: GridToolbar}}
                                rows={isLoading ? [] : data.data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 100]}
                                localeText={tableLang}
                                disableRowSelectionOnClick
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SupplierPage;
