import React, {useContext} from "react";
import {BarChart, LineChart, PieChart} from "@mui/x-charts";
import {Button, Chip, Container, Divider, Grid, Paper} from "@mui/material";
import {useNavigate} from "react-router";
import PeopleIcon from "@mui/icons-material/People";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {LanguageContext} from "../../components/LanguageContext";


const DashboardPage = () => {
    const role = localStorage.getItem("role");
    const navigate = useNavigate();
    const store_id = localStorage.getItem("store_id");
    const {translations} = useContext(LanguageContext) || {};

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{display: "flex", flexDirection: "column"}}>
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{xs: 1, sm: 2, md: 3}}
                            sx={{p: 5}}
                        >
                            <Grid item xs={12}>
                                <BarChart
                                    xs={12}
                                    series={[
                                        {data: [35, 44, 24, 34]},
                                        {data: [51, 6, 49, 30]},
                                        {data: [15, 25, 30, 50]},
                                        {data: [60, 50, 15, 25]},
                                    ]}
                                    height={290}
                                    xAxis={[
                                        {data: ["Q1", "Q2", "Q3", "Q4"], scaleType: "band"},
                                    ]}
                                    margin={{top: 10, bottom: 30, left: 40, right: 10}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label={translations?.adminDashboardPageAnalytics || "Analytics"}/>
                                </Divider>
                            </Grid>
                            <Grid item xs={6}>
                                <PieChart
                                    series={[
                                        {
                                            data: [
                                                {
                                                    id: 0,
                                                    value: 10,
                                                    label: translations?.adminDashboardPagePieSeriesA || "series A"
                                                },
                                                {
                                                    id: 1,
                                                    value: 15,
                                                    label: translations?.adminDashboardPagePieSeriesB || "series B"
                                                },
                                                {
                                                    id: 2,
                                                    value: 20,
                                                    label: translations?.adminDashboardPagePieSeriesC || "series C"
                                                },
                                            ],
                                        },
                                    ]}
                                    width={400}
                                    height={200}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <LineChart
                                    xAxis={[{data: [1, 2, 3, 5, 8, 10]}]}
                                    series={[
                                        {
                                            data: [2, 5.5, 2, 8.5, 1.5, 5],
                                        },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{mb: 2}}>
                                <Divider>
                                    <Chip
                                        label={translations?.adminDashboardPageAdministrationSection || "Section - Administration"}/>
                                </Divider>
                            </Grid>
                            <Grid item xs={4} sx={{p: 3}}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    endIcon={<LocalShippingTwoToneIcon/>}
                                    href="/administrator/dobavljaci"
                                >
                                    {translations?.adminDashboardPageSuppliers || "Suppliers"}
                                </Button>
                            </Grid>
                            <Grid item xs={4} sx={{p: 3}}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    endIcon={<PeopleIcon/>}
                                    href="/administrator/korisnici"
                                >
                                    {translations?.adminDashboardPageUsers || "Users"}
                                </Button>
                            </Grid>
                            <Grid item xs={4} sx={{p: 3}}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    endIcon={<ShoppingCartIcon/>}
                                    href="/administrator/prodavnice"
                                >
                                    {translations?.adminDashboardPageStores || "Stores"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default DashboardPage;
