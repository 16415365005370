import React, {useContext, useState} from 'react';
import {useQuery} from "react-query";
import {getInventoryByStore} from "../../api/inventoryApi";
import {Alert, CircularProgress, Container, Grid, Paper} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useTableLang} from "../../utils/tableLang";
import {LanguageContext} from "../../components/LanguageContext";

const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 50,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "store_code",
        headerName: "Šifra prodavnice",
        width: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "code",
        headerName: "Šifra proizvoda",
        minWidth: 150,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "name",
        headerName: "Naziv proizvoda",
        minWidth: 300,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "amount",
        headerName: "Količina",
        flex: 1,
        minWidth: 150,
        sortable: false,
        filterable: false,
        hideable: false,
        align: "center",
        headerAlign: "center",
    },
];

const PoslovodjaLager = () => {
    const tableLang = useTableLang();
    const {translations} = useContext(LanguageContext) || {};
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const storeCode = localStorage.getItem('store_code');

    const {data, isLoading} = useQuery({
        queryKey: ["inventory", storeCode],
        queryFn: () => getInventoryByStore(storeCode),
    });

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            {success === "" ? null : (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error === "" ? null : (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <div style={{height: 500, width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    slots={{toolbar: GridToolbar}}
                                    rows={isLoading ? [] : data.data}
                                    columns={columns.map(col => ({
                                        ...col,
                                        headerName: translations[`poslovodjaLager${col.field}`] || col.headerName
                                    }))}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PoslovodjaLager;
