import React, {useContext} from 'react';
import {useParams} from "react-router";
import {useQuery} from "react-query";
import {getOrderInfo} from "../../../api/orderApi";
import {Box, CircularProgress, Container, Divider, Grid, Paper} from "@mui/material";
import {LanguageContext} from "../../../components/LanguageContext";

const OrderInfoPage = () => {
    let {id} = useParams();
    const {translations} = useContext(LanguageContext) || {};

    const {data, isLoading, isError} = useQuery({
        queryKey: ['getOrderInfo', id],
        queryFn: () => getOrderInfo(id)
    });

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <div style={{width: "100%"}}>
                            {isLoading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <Box>
                                    <Box sx={{p: 2, display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                                        <p>{translations.poslovodjaCreatedOrdersInfo || "Informacije o narudžbenici"}</p>
                                        <p style={{
                                            fontSize: 16,
                                            fontWeight: 'bold'
                                        }}>
                                            {translations.poslovodjaCreatedOrdersTime || "Vreme"}: {(new Date((JSON.parse(data?.data.order_response).date))).toLocaleString('sr-RS', {timeZoneName: "short"})}
                                        </p>
                                        <p style={{
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        }}>
                                            {translations.poslovodjaCreatedOrdersId || "ID"}: {(JSON.parse(data?.data.order_response).processId)}
                                        </p>
                                        <p style={{
                                            fontSize: 14,
                                            fontWeight: 'bold'
                                        }}>
                                            {translations.poslovodjaCreatedOrdersCode}: {(JSON.parse(data?.data.order_response).documentNumber)}
                                        </p>
                                    </Box>
                                    <Divider/>
                                    <Box sx={{
                                        p: 2,
                                        height: 500,
                                        overflowY: 'scroll',
                                        justifyContent: 'center',
                                        textAlign: 'center'
                                    }}>
                                        {JSON.parse(data?.data.order_response).order_items.map((item, index) => (
                                            <Box sx={{flex: 1, flexDirection: "row", justifyContent: "center"}}>
                                                <p style={{fontWeight: 'bold'}}>{translations.poslovodjaAcquisitionPageColumnProductCode}: {item.productCode}</p>
                                                <p style={{fontStyle: 'italic'}}>{translations.supplierCode}: {item.supplierCode}</p>
                                                <p style={{fontStyle: 'italic'}}>{translations.quantity}: {item.quantity.toFixed(0)}</p>
                                                <Divider/>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default OrderInfoPage;