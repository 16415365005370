import React, {useContext, useState} from "react";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useTableLang} from "../../utils/tableLang";
import {Alert, Box, Button, CircularProgress, Container, Divider, Grid, Modal, Paper, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import CalculateTwoToneIcon from "@mui/icons-material/CalculateTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import {MuiFileInput} from "mui-file-input";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getCalculations, postCalculation} from "../../api/calculationApi";
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import ScheduleTwoToneIcon from "@mui/icons-material/ScheduleTwoTone";
import {LanguageContext} from "../../components/LanguageContext";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FolderCopyIcon from "@mui/icons-material/FolderCopy";

const SingleStorePage = () => {
    const tableLang = useTableLang();
    const {translations} = useContext(LanguageContext);
    const [openModal, setOpenModal] = useState(false);
    const [excel, setExcel] = useState(null);
    const [excelError, setExcelError] = useState("");

    const [loading, setLoading] = useState(false);

    const [mutationMessage, setMutationMessage] = useState("");
    const [mutationError, setMutationError] = useState("");

    const queryClient = useQueryClient();

    const {storeId, storeCode} = useParams();
    const columns = [
        {
            field: "id",
            headerName: translations.adminSingleStorePageId,
            width: 70,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "file_name",
            headerName: translations.adminSingleStorePageFileName,
            minWidth: 300,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "s_name",
            headerName: translations.adminSingleStorePageStoreName,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "date_time",
            headerName: translations.adminSingleStorePageTime,
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "info",
            headerName: translations.adminSingleStorePageInfo,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    href={`/administrator/prodavnica/${storeCode}/informacije/${params.row.id}`}
                >
                    {translations.adminSingleStorePageInfo}
                </Button>
            ),
        },
    ];

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false);
        setMutationMessage("");
    };

    const handleChange = (newFile) => {
        setExcel(newFile);
    };

    const submitData = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!excel) {
            setExcelError(translations.adminSingleStorePageFileError);
            return;
        }
        setExcelError("");
        const formData = new FormData();

        formData.append("excel", excel, excel.name);
        formData.append("store_code", storeCode);
        formData.append("user_id", localStorage.getItem("user_id"));
        try {
            mutation.mutateAsync(formData).catch((e) => {
            });
        } catch (e) {
            // do nothing
        }
    };

    const mutation = useMutation({
        mutationFn: (formData) => postCalculation(formData),
        onSuccess: (response) => {
            setMutationMessage(response.data.message);
            setMutationError("");
            queryClient.invalidateQueries(["calculations", storeCode]);
            setTimeout(() => {
                setLoading(false);
            }, 4000);
        },
        onError: (error) => {
            setMutationMessage("");
            setMutationError(error.response.data.message);
        },
    });

    const {data: calculations, isLoading: isLoadingCalculations} = useQuery({
        queryKey: ["calculations", storeCode],
        queryFn: () => getCalculations(storeCode),
    });

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeCode}/narudzbine`}
                            fullWidth
                        >
                            <FolderCopyIcon sx={{mr: 1}}/>
                            {translations.createdOrders}
                        </Button>
                    </Paper>
                </Grid>
                {mutationError == "" ? null :
                    <Grid item xs={12}>
                        <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                            <Alert severity="error">{mutationError}</Alert>
                        </Paper>
                    </Grid>}
                {mutationMessage == "" ? null :
                    <Grid item xs={12}>
                        <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                            <Alert severity="success">{mutationMessage}</Alert>
                        </Paper>
                    </Grid>}

                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button disabled={loading} variant="contained" size="large" onClick={handleOpen}
                                fullWidth>
                            <CalculateTwoToneIcon sx={{mr: 1}}/>
                            {translations.adminSingleStorePageNewCalculation}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button variant="contained" size="large"
                                href={`/administrator/prodavnica/${storeCode}/proizvodi`} fullWidth>
                            <Inventory2TwoToneIcon sx={{mr: 1}}/>
                            {translations.adminSingleStorePageProducts}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button variant="contained" size="large"
                                href={`/administrator/prodavnica/${storeCode}/istorijat`} fullWidth>
                            <ScheduleTwoToneIcon sx={{mr: 1}}/>
                            {translations.adminSingleStorePageHistory}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button variant="contained" size="large"
                                href={`/administrator/prodavnica/${storeCode}/lager`} fullWidth>
                            <WarehouseIcon sx={{mr: 1}}/>
                            {translations.adminAdministratorMenuWarehouse}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <div style={{height: 600, width: "100%"}}>
                            {isLoadingCalculations || loading ? (
                                <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    rows={isLoadingCalculations ? [] : calculations?.data}
                                    slots={{toolbar: GridToolbar}}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 8,
                        p: 4,
                    }}
                >
                    {mutationError === "" ? null : <Alert severity="error">{mutationError}</Alert>}
                    {mutationMessage === "" ? null : <Alert severity="success">{mutationMessage}</Alert>}
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{textAlign: "center", marginBottom: 4, mt: 2}}
                    >
                        <CalculateTwoToneIcon/> {translations.adminSingleStorePageCalculate}
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            "& .MuiTextField-root": {m: 1, width: "35ch"},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {excelError === "" ? null :
                            <Alert severity="error" sx={{justifyContent: "center"}}>{excelError}</Alert>}
                        <Divider sx={{mb: 2, mt: 2}}/>
                        <MuiFileInput
                            value={excel}
                            onChange={handleChange}
                            required
                            id="lager"
                            label={translations.adminSingleStorePageSelectFile}
                            name="lager"
                            inputProps={{accept: ".xls, .xlsx, .csv"}}
                            clearIconButtonProps={{
                                title: "Obriši",
                                children: <CloseIcon fontSize="small"/>,
                            }}
                        />
                        <Button
                            type="submit"
                            sx={{width: "100%", marginTop: 4}}
                            variant="contained"
                            size="large"
                            onClick={(e) => {
                                submitData(e);
                            }}
                        >
                            <CalculateTwoToneIcon/> {translations.adminSingleStorePageCalculate}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
};

export default SingleStorePage;
