import React, {useContext, useState} from "react";
import {Alert, Button, CircularProgress, Container, FormControlLabel, Grid, Paper} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useTableLang} from "../../../utils/tableLang";
import InfoIcon from "@mui/icons-material/Info";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {fetchStores, getStores, updateStoreActivity} from "../../../api/storesApi";
import CachedIcon from "@mui/icons-material/Cached";
import {fetchProducts} from "../../../api/productsApi";
import {LanguageContext} from "../../../components/LanguageContext";
import Switch from "@mui/material/Switch";

export const StoresPage = () => {
    const tableLang = useTableLang();
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const [loadingAllStores, setLoadingAllStores] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {translations} = useContext(LanguageContext); // Accessing translations

    const handleChangeActivity = (event, store_code) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('store_code', store_code);

        mutationActivity.mutateAsync(formData).catch((e) => {
        });
    }

    const columns = (translations) => [
        {
            field: "id",
            headerName: translations.adminStoresPageStoreId,
            width: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "code",
            headerName: translations.adminStoresPageStoreCode,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations.adminStoresPageStoreName,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "active",
            headerName: translations.active,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <FormControlLabel
                        value="start"
                        control={<Switch color="primary" checked={params.row.active}
                                         onChange={(event) => handleChangeActivity(event, params.row.code)}/>}
                        labelPlacement="start"
                        label={params.row.active == 1 ? translations.activeStore : translations.disabledStore}/>
                )
            }
        },
        {
            field: "info",
            headerName: translations.adminStoresPageStoreInfo,
            flex: 1,
            minWidth: 150,
            sortable: false,
            filterable: false,
            hideable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    href={`/administrator/prodavnica/${params.row.code}`}
                    disabled={!params.row.active}
                >
                    <InfoIcon sx={{marginRight: 1}}/> {translations.adminStoresPageStoreInfo}
                </Button>
            ),
        },
    ];

    const {data, isLoading} = useQuery({
        queryKey: ["stores"],
        queryFn: () => getStores(),
    });

    const mutation = useMutation({
        mutationFn: () => fetchStores(),
        onSuccess: (resp) => {
            setError("");
            queryClient.invalidateQueries(["stores"]);
            setSuccess(resp.data.message);
            setLoading(false);
        },
        onError: (err) => {
            setSuccess("");
            setLoading(false);
            setError(err.response.data.message);
        },
    });

    const mutationActivity = useMutation({
        mutationFn: (formData) => updateStoreActivity(formData),
        onSuccess: (resp) => {
            setError("");
            queryClient.invalidateQueries(["stores"]);
            setSuccess(resp.data.message);
            setLoading(false);
        },
        onError: (err) => {
            setSuccess("");
            setLoading(false);
            setError(err.response.data.message);
        },
    })

    const mutationAllProducts = useMutation({
        mutationFn: () => fetchProducts(),
        onSuccess: (resp) => {
            setError("");
            setSuccess(resp.data.message);
            setLoadingAllStores(false);
        },
        onError: (err) => {
            setSuccess("");
            setLoadingAllStores(false);
            setError(err.response.data.message);
        },
    });

    const fetchStoresHandle = () => {
        setLoading(true);
        mutation.mutateAsync().catch(() => setLoading(false));
    };

    const fetchAllProductsHandle = () => {
        setLoadingAllStores(true);
        mutationAllProducts.mutateAsync().catch(() => setLoadingAllStores(false));
    };

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            {success && (
                <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                    {success}
                </Alert>
            )}
            {error && (
                <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                    {error}
                </Alert>
            )}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <Grid container spacing={1} sx={{mb: 2}}>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={fetchStoresHandle}
                                    fullWidth
                                >
                                    {loading ? <CircularProgress size={24} color="inherit"/> : (
                                        <CachedIcon sx={{mr: 1}}/>
                                    )}
                                    {loading ? translations.adminStoresPageFetchingStores : translations.adminStoresPageUpdateStores}
                                </Button>
                            </Grid>
                            <Grid item lg={6} sm={6} xs={12}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={fetchAllProductsHandle}
                                    fullWidth
                                >
                                    {loadingAllStores ? <CircularProgress size={24} color="inherit"/> : (
                                        <CachedIcon sx={{mr: 1}}/>
                                    )}
                                    {loadingAllStores ? translations.adminStoresPageFetchingProducts : translations.adminStoresPageUpdateProducts}
                                </Button>
                            </Grid>
                        </Grid>
                        <div style={{height: 800, width: "100%"}}>
                            {isLoading ? (
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    slots={{toolbar: GridToolbar}}
                                    rows={isLoading ? [] : data.data}
                                    columns={columns(translations)}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};
