import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import CalculateTwoToneIcon from "@mui/icons-material/CalculateTwoTone";
import {calculateOrder} from "../api/orderApi";
import {LanguageContext} from "./LanguageContext";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {fetchInventory} from "../api/inventoryApi";

const UpdateButtons = ({
                           calc_id,
                           store_code,
                           success,
                           setSuccess,
                           error,
                           setError,
                       }) => {
    const queryClient = useQueryClient();
    const {translations} = useContext(LanguageContext) || {};

    const [loading1, setLoading1] = useState(false);
    const [loading3, setLoading3] = useState(false);

    const mutationInventory = useMutation({
        mutationFn: (formData) => fetchInventory(),
        onSuccess: (response) => {
            setTimeout(() => {
                setSuccess(translations?.updateInventorySuccessMessage);
                setLoading1(false);
            }, 20000);

            setTimeout(() => {
                setSuccess("");
            }, 10000);
        },
        onError: (error) => {
            setError(translations?.updateInventoryErrorMessage);
            setLoading1(false);

            setTimeout(() => {
                setError("");
            }, 10000);
        },
    });

    const mutationOrder = useMutation({
        mutationFn: (formData) => calculateOrder(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["orderGet"]);
            setSuccess(translations?.adminUpdateButtonsSuccessOrder || "Successfully calculated the order!");
            setLoading3(false);

            setTimeout(() => {
                setSuccess("");
            }, 10000);
        },
        onError: (error) => {
            queryClient.invalidateQueries(["orderGet"]);
            setError(translations?.adminUpdateButtonsErrorOrder || "Failed to calculate the order!");
            setLoading3(false);

            setTimeout(() => {
                setError("");
            }, 10000);
        },
    });

    const submitUpdateInventory = () => {
        setLoading1(true);
        mutationInventory.mutateAsync().catch((e) => {
        });
    };

    const submitOrder = () => {
        const formData = new FormData();
        formData.append("calc_id", calc_id);
        formData.append("store_code", store_code);
        setLoading3(true);
        mutationOrder.mutateAsync(formData).catch((e) => {
        });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xl={6} lg={6} xs={12}>
                <Button
                    onClick={submitOrder}
                    variant="contained"
                    disabled={loading3}
                    fullWidth
                    color="secondary"
                >
                    {loading3 ? (
                        <CircularProgress
                            color="primary"
                            thickness={5}
                            sx={{mr: 2}}
                            size={22}
                        />
                    ) : (
                        <CalculateTwoToneIcon sx={{mr: 1}}/>
                    )}
                    <Typography sx={{fontWeight: "bold", fontSize: 15}}>
                        {translations?.adminUpdateButtonsCalculateOrder || "Calculate Order"}
                    </Typography>
                </Button>
            </Grid>
            <Grid item xl={6} lg={6} xs={12}>
                <Button
                    onClick={submitUpdateInventory}
                    variant="contained"
                    disabled={loading1}
                    fullWidth
                    color="secondary"
                >
                    {loading1 ? (
                        <CircularProgress
                            color="primary"
                            thickness={5}
                            sx={{mr: 2}}
                            size={22}
                        />
                    ) : (
                        <SyncAltIcon sx={{mr: 1}}/>
                    )}
                    <Typography sx={{fontWeight: "bold", fontSize: 15}}>
                        {translations?.updateInventory}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
};

export default UpdateButtons;
