import React, {useContext} from 'react';
import {useQuery} from "react-query";
import {CircularProgress, Container, Grid, Paper, Typography} from "@mui/material";
import {getProductsByStore} from "../../api/productsApi";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useTableLang} from "../../utils/tableLang";
import {LanguageContext} from "../../components/LanguageContext";

const PoslovodjaProizvodi = () => {
    const tableLang = useTableLang();
    const {translations} = useContext(LanguageContext) || {};
    const storeCode = localStorage.getItem('store_code');

    const products = [
        {
            field: "id",
            headerName: translations.poslovodjaProizvodiId || "ID",
            minWidth: 100,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "code",
            headerName: translations.poslovodjaProizvodiCode || "Šifra",
            width: 150,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations.poslovodjaProizvodiName || "Naziv proizvoda",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "ean",
            headerName: translations.poslovodjaProizvodiEan || "EAN",
            minWidth: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "group",
            headerName: translations.poslovodjaProizvodiGroup || "Grupa",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Typography>{params.row.group == null ? translations.poslovodjaProizvodiNoGroup || "Nema" : params.row.group}</Typography>
            )
        },
        {
            field: "supplier_name",
            headerName: translations.poslovodjaProizvodiSupplierName || "Naziv dobavljača",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Typography>{params.row.supplier_name == null ? translations.poslovodjaProizvodiNoSupplier || "Nema" : params.row.supplier_name}</Typography>
            )
        },
    ];

    const {data: productsByStore, isLoading: isLoadingProducts} = useQuery({
        queryKey: ['productsByStore', storeCode],
        queryFn: () => getProductsByStore(storeCode),
    });

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        <div style={{height: 600, width: "100%"}}>
                            {isLoadingProducts ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    rows={isLoadingProducts ? [] : productsByStore?.data}
                                    slots={{toolbar: GridToolbar}}
                                    columns={products}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick={true}
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PoslovodjaProizvodi;
