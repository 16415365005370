import axios from "axios";
import {BASE_PATH} from "../services/api";

export const getOrder = (calc_id) => {
    const response = axios.get(BASE_PATH + "/admin/get-order/" + calc_id, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
};

export const calculateOrder = (formData) => {
    const response = axios.post(BASE_PATH + "/admin/calculate-order", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
};

export const updateHandKolicina = (formData) => {
    const response = axios.post(BASE_PATH + "/admin/update-hand-kolicina", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
}

export const createOrder = (formData) => {
    const response = axios.post(BASE_PATH + "/admin/create-order", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
}

export const getCreatedOrder = (id_calculation) => {
    const response = axios.get(BASE_PATH + "/admin/get-created-order/" + id_calculation, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
}

export const getOrderByCalculation = (id_calculation) => {
    return axios.get(BASE_PATH + "/admin/get-created-orders-by-calculation/" + id_calculation, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
};

export const getOrderByStore = (store_code) => {
    return axios.get(BASE_PATH + "/admin/get-created-orders-by-store/" + store_code, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
};

export const getOrderInfo = (order_id) => {
    return axios.get(BASE_PATH + "/admin/get-created-order-info/" + order_id, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
};

export const deleteCreatedOrder = (formData) => {
    const response = axios.post(BASE_PATH + "/admin/delete-created-order", formData, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    return response;
}