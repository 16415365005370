import React, {useContext, useState} from 'react';
import {Alert, Autocomplete, Box, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import {useMutation, useQuery} from "react-query";
import {createUser} from "../../../api/userApi";
import {getStores} from "../../../api/storesApi";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import {LanguageContext} from "../../../components/LanguageContext"; // Import Language Context

const CreateUserPage = () => {
    const {translations} = useContext(LanguageContext) || {};
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [prodavnica, setProdavnica] = useState(null);

    const {data, isLoading, isError} = useQuery({
        queryKey: ["stores"],
        queryFn: () => getStores(),
    });

    const mutation = useMutation({
        mutationFn: (data) => createUser(data),
        onSuccess: (response) => {
            setError(null);
            setSuccess(translations?.adminCreateUserPageSuccessMessage || "New manager created successfully!");

            setTimeout(() => {
                navigate('../../administrator/korisnici');
            }, 2000);
        },
        onError: (error) => {
            setSuccess(null);
            setError(translations?.adminCreateUserPageErrorMessage || "An error occurred while creating the manager.");
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        formData.append('store_id', prodavnica.id);
        formData.append('store_code', prodavnica.code);

        if (formData.get('name') === "") {
            setError(translations?.adminCreateUserPageNameRequired || 'First name is required!');
            return;
        }

        if (formData.get('surname') === "") {
            setError(translations?.adminCreateUserPageSurnameRequired || 'Last name is required!');
            return;
        }

        if (formData.get('email') === "") {
            setError(translations?.adminCreateUserPageEmailRequired || 'Email is required!');
            return;
        }

        if (prodavnica === null) {
            setError(translations?.adminCreateUserPageStoreRequired || 'Store is required!');
            return;
        }

        try {
            mutation.mutateAsync(formData).catch((e) => {
            });
        } catch (error) {
            // do nothing
        }
    };

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        {success == null ? null : (
                            <Alert variant="filled" severity="success">
                                {success}
                            </Alert>
                        )}
                        {error == null ? null : (
                            <Alert variant="filled" severity="error">
                                {error}
                            </Alert>
                        )}
                        <Typography variant="h6" color="textSecondary">
                            {translations?.adminCreateUserPageAddButton || "Novi poslovodja"}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <TextField
                                name="name"
                                required
                                sx={{mt: 2}}
                                fullWidth
                                id="outlined-basic"
                                label={translations?.adminCreateUserPageName || "Ime"}
                                variant="outlined"
                            />
                            <TextField
                                name="surname"
                                required
                                sx={{mt: 2}}
                                fullWidth
                                id="outlined-basic"
                                label={translations?.adminCreateUserPageSurname || "Prezime"}
                                variant="outlined"
                            />
                            <TextField
                                name="email"
                                required
                                sx={{mt: 2}}
                                fullWidth
                                id="outlined-basic"
                                label={translations?.adminCreateUserPageEmail || "Email"}
                                variant="outlined"
                            />
                            <Autocomplete
                                disablePortal
                                id="prodavnica"
                                name="prodavnica"
                                options={isLoading ? [] : data?.data}
                                loading={isLoading}
                                fullWidth
                                sx={{mt: 2}}
                                renderInput={(params) => (
                                    <TextField {...params}
                                               label={translations?.adminCreateUserPageStore || "Izaberite prodavnicu"}/>
                                )}
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setProdavnica(newValue);
                                    }
                                }}
                                value={prodavnica}
                                getOptionLabel={(option) => option.name || ""}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 2, mb: 2}}
                            >
                                {translations?.adminCreateUserPageAddButton || "Dodajte novog poslovodju"}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CreateUserPage;
