import React, {useContext, useState} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {Alert, Button, CircularProgress} from "@mui/material";
import {useTableLang} from "../../utils/tableLang";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import AbcTwoToneIcon from "@mui/icons-material/AbcTwoTone";
import {useParams} from "react-router";
import {getForecast, getForecastDates} from "../../api/forecastApi";
import {useQuery} from "react-query";
import UpdateButtons from "../../components/UpdateButtons";
import {LanguageContext} from "../../components/LanguageContext";

const ExcelProInfoPage = () => {
    const tableLang = useTableLang();
    const {storeId, excelId} = useParams();
    const {translations} = useContext(LanguageContext) || {};

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data: data1, isLoading: isLoading1} = useQuery({
        queryKey: ["forecast", excelId],
        queryFn: () => getForecast(excelId),
    });

    const {data: data2, isLoading: isLoading2} = useQuery({
        queryKey: ["dates", excelId],
        queryFn: () => getForecastDates(excelId),
    });

    let rows = [];
    let dates = [];

    if (!isLoading1) {
        rows = data1.data.map((d) => {
            const row = {
                id: d.id,
                code: d.code,
                name: d.name,
            };

            const dateAmountArray = JSON.parse(d.forecast);

            dateAmountArray.forEach((item) => {
                row[`${item.date}`] = parseFloat(item.amount).toFixed(2);
            });

            return row;
        });
    }

    if (!isLoading2) {
        dates = data2.data;
    }

    const columns = [
        {
            field: "code",
            headerName: translations?.adminExcelProInfoPageProductCode || "Product Code",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations?.adminExcelProInfoPageProductName || "Product Name",
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        ...dates.map((item) => ({
            field: `${item}`,
            headerName: `${translations?.adminExcelProInfoPageDate || "Date"} ${item}`,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        })),
    ];

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/abc-ved`}
                            fullWidth
                        >
                            <AbcTwoToneIcon sx={{mr: 1}}/>
                            {translations?.adminExcelProInfoPageABCVed || "ABC-VED"}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/analiza`}
                            fullWidth
                        >
                            <LeaderboardIcon sx={{mr: 1}}/>
                            {translations?.adminExcelProInfoPageAnalysis || "Analysis"}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/nabavka`}
                            fullWidth
                        >
                            <InventoryIcon sx={{mr: 1}}/>
                            {translations?.adminExcelProInfoPageAcquisition || "Acquisition"}
                        </Button>
                    </Paper>
                </Grid>
                {success === "" ? null : (
                    <Grid item xs={12}>
                        <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                            <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                                {success}
                            </Alert>
                        </Paper>
                    </Grid>
                )}
                {error === "" ? null : (
                    <Grid item xs={12}>
                        <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                            <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                                {error}
                            </Alert>
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <UpdateButtons
                            calc_id={excelId}
                            store_code={storeId}
                            success={success}
                            setSuccess={setSuccess}
                            error={error}
                            setError={setError}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        {
                            data1?.data.length == 0 || data1?.data == undefined || data1?.data == null || data2?.data.length == 0 || data2?.data == undefined || data2?.data == null ? (
                                <Alert severity="warning">{translations?.messageIfEmptyData}</Alert>
                            ) : (
                                <div style={{height: 800}}>
                                    {isLoading1 || isLoading2 ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <CircularProgress/>
                                        </div>
                                    ) : (
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            slots={{toolbar: GridToolbar}}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {page: 0, pageSize: 25},
                                                },
                                            }}
                                            localeText={tableLang}
                                            pageSizeOptions={[25, 50, 100]}
                                            disableRowSelectionOnClick
                                        />
                                    )}
                                </div>
                            )
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ExcelProInfoPage;
