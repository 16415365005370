import React, {useContext, useEffect, useState} from 'react';
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import {srRS} from "@mui/material/locale";
import {useNavigate} from "react-router";
import {logoutApi} from "../api/authApi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import Spinner from "../components/Spinner";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import DarkModeSwitch from "../components/DarkModeSwitch";
import AccountCircle from "@mui/icons-material/AccountCircle";
import kaproscmLogo from "../assets/kaproscmLogo.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Copyright from "../components/Copyright";
import PoslovodjaMenu from "../components/PoslovodjaMenu";
import {LanguageContext} from '../components/LanguageContext';
import Tooltip from '@mui/material/Tooltip';
import Flag from 'react-world-flags'; // Ensure correct flag component import

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const defaultTheme = createTheme(srRS);
const darkTheme = createTheme(
    {
        palette: {
            mode: "dark",
        },
    },
    srRS
);

const PoslovodjaLayout = ({children}) => {
    const {switchLanguage, translations} = useContext(LanguageContext) || {};
    const storedTheme = localStorage.getItem("theme");
    const [open, setOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [theme, setTheme] = useState(
        storedTheme === "dark" ? darkTheme : defaultTheme
    );
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundColor =
            theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900];
    }, [theme]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleToggleTheme = () => {
        const newTheme = theme === defaultTheme ? darkTheme : defaultTheme;
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme === darkTheme ? "dark" : "light");
    };

    const isMenuOpen = Boolean(anchorEl);
    const menuId = "primary-account-menu";
    const userId = localStorage.getItem("user_id");

    const handleLogout = async (e) => {
        e.preventDefault();
        await logoutApi();
        navigate("../");
        setTheme(defaultTheme);
        localStorage.setItem("theme", "light");
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => navigate(`/poslovodja/profil/${userId}`)}>
                <PersonIcon sx={{mr: 1}}/> {translations?.adminProfile || "Profile"}
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <LogoutIcon sx={{mr: 1}}/> {translations?.adminLogout || "Logout"}
            </MenuItem>
        </Menu>
    );

    const handleLanguageSwitch = () => {
        const newLanguage = localStorage.getItem('language') === 'en' ? 'sr' : 'en';
        localStorage.setItem('language', newLanguage);
        switchLanguage(); // Switch the language in context
    };

    const [spinner, setSpinner] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => setSpinner(false), 2000);
        return () => clearTimeout(timer);
    }, []);

    if (spinner) {
        return <Spinner/>;
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <AppBar
                    position="absolute"
                    open={open}
                    sx={{backgroundColor: "#295ed9"}}
                >
                    <Toolbar sx={{pr: "24px"}}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && {display: "none"}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            {translations?.adminDashboard || "Dashboard"}
                        </Typography>
                        <Box sx={{display: "flex", alignItems: "center", ml: 2}}>
                            <DarkModeSwitch
                                onChange={handleToggleTheme}
                                checked={localStorage.getItem("theme") === "dark"}
                            />
                            <Tooltip
                                title={localStorage.getItem('language') === 'en' ? 'Switch to Serbian' : 'Switch to English'}>
                                <IconButton
                                    onClick={handleLanguageSwitch}
                                    color="inherit"
                                    sx={{ml: 2}}
                                >
                                    <Flag code={localStorage.getItem('language') === 'en' ? 'GB' : 'RS'}
                                          style={{width: 24, height: 24}}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                        {renderMenu}
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                        }}
                    >
                        <img src={kaproscmLogo} height={35} width={35} alt="Logo"/>
                        <Typography
                            component="h1"
                            variant="h5"
                            color="inherit"
                            noWrap
                            sx={{marginLeft: 2}}
                        >
                            <b>KaproSCM</b>
                        </Typography>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        <PoslovodjaMenu/>
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto"
                    }}
                >
                    <Toolbar/>
                    {children}
                    <Copyright sx={{pt: 4}}/>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PoslovodjaLayout;
