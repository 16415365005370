import React, {useContext, useState} from 'react';
import {useParams} from "react-router";
import {LanguageContext} from "../../../components/LanguageContext";
import {useTableLang} from "../../../utils/tableLang";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deleteCreatedOrder, getOrderByStore} from "../../../api/orderApi";
import {Alert, Button, CircularProgress, Modal} from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const OrdersByStorePagePoslovodja = () => {
    let {storeId} = useParams();
    const {translations} = useContext(LanguageContext);
    const tableLang = useTableLang();
    const queryClient = useQueryClient();

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [orderId, setOrderId] = useState(null);
    const [documentNumber, setDocumentNumber] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {data, isLoading, isError} = useQuery({
        queryKey: ['getOrderByStore', storeId],
        queryFn: () => getOrderByStore(storeId)
    });

    const mutation = useMutation({
        mutationFn: (formData) => deleteCreatedOrder(formData),
        onSuccess: (response) => {
            queryClient.invalidateQueries(["getOrderByStore", storeId]);
            setSuccess(response.data.message);
            setError("");

            setTimeout(() => {
                setSuccess("");
            }, 10000);
        },
        onError: (error) => {
            setSuccess("");
            setError(error.response.data.message);

            setTimeout(() => {
                setError("");
            }, 10000);
        }
    });

    const handleDeleteOrder = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('id', orderId);

        mutation.mutateAsync(formData).catch((e) => {
        });
    }

    const columns = [
        {
            field: "id",
            headerName: translations.adminAcquisitionPageColumnID,
            minWidth: 50,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations.adminUserProfileName,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "surname",
            headerName: translations.adminUserProfileSurname,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "documentNumber",
            headerName: translations.poslovodjaCreatedOrdersCode,
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
                return JSON.parse(params.row.order_response).documentNumber;
            },
            valueGetter: (value, row) => {
                // Extract the documentNumber for filtering and sorting
                return JSON.parse(row.order_response).documentNumber;
            }
        },
        {
            field: "warehouseCode",
            headerName: translations.poslovodjaLagerstore_code,
            minWidth: 100,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
                return JSON.parse(params.row.order_response).warehouseCode;
            },
            valueGetter: (value, row) => {
                // Extract the documentNumber for filtering and sorting
                return JSON.parse(row.order_response).warehouseCode;
            }
        },
        {
            field: "accountCode",
            headerName: translations.supplierCode,
            minWidth: 100,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: (params) => {
                return JSON.parse(params.row.order_response).accountCode;
            },
            valueGetter: (value, row) => {
                // Extract the documentNumber for filtering and sorting
                return JSON.parse(row.order_response).accountCode;
            }
        },
        {
            field: "id_calculation",
            headerName: translations.calculationCode,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "timestamp",
            headerName: translations.time,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "informacije",
            headerName: translations.information,
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            flex: 2,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        href={`/poslovodja/narudzbina/` + params.row.id}
                        fullWidth
                    >
                        <InfoIcon sx={{mr: 1}}/>
                        {translations.information}
                    </Button>
                )
            }
        },
        {
            field: "brisanje",
            headerName: translations.delete,
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            filterable: false,
            sortable: false,
            flex: 2,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        fullWidth
                        color="error"
                        onClick={(e) => {
                            setOrderId(params.row.id);
                            setDocumentNumber(JSON.parse(params.row.order_response).documentNumber);
                            setOpen(true);
                        }}
                    >
                        <DeleteIcon sx={{mr: 1}}/>
                        {translations.delete}
                    </Button>
                )
            }
        }
    ];

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column", height: 600}}>
                        {success === "" ? null : (
                            <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                                {success}
                            </Alert>
                        )}
                        {error === "" ? null : (
                            <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                                {error}
                            </Alert>
                        )}
                        {isLoading ? (
                            <CircularProgress/>
                        ) : (
                            <DataGrid
                                rows={isLoading ? [] : data?.data}
                                columns={columns}
                                slots={{toolbar: GridToolbar}}
                                initialState={{
                                    pagination: {
                                        paginationModel: {pageSize: 25},
                                    },
                                }}
                                localeText={tableLang}
                                pageSizeOptions={[25, 50, 100]}
                                disableRowSelectionOnClick
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="delete-created-order-modal"
                aria-labelledby="delete-created-order-modal"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: "center"}}>
                        ID: {orderId} <br/> #: {documentNumber}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2, mb: 2, textAlign: "center"}}>
                        {translations.modalTextCreatedOrder}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={handleDeleteOrder}
                            >
                                <DeleteIcon sx={{mr: 1}}/>
                                {translations.adminYes}
                            </Button>
                        </Grid>
                        <Grid item xl={6} lg={6} xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="success"
                                onClick={handleClose}
                            >
                                <CloseIcon sx={{mr: 1}}/>
                                {translations.adminNo}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Container>
    );
};

export default OrdersByStorePagePoslovodja;