import React, {useContext, useState} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {Alert, Button, CircularProgress} from "@mui/material";
import {useTableLang} from "../../utils/tableLang";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import OnlinePredictionTwoToneIcon from "@mui/icons-material/OnlinePredictionTwoTone";
import {useParams} from "react-router";
import {useQuery} from "react-query";
import UpdateButtons from "../../components/UpdateButtons";
import {getABCVED} from "../../api/abcvedApi";
import {LanguageContext} from "../../components/LanguageContext";

const AbcVedPage = () => {
    const tableLang = useTableLang();
    const {storeId, excelId} = useParams();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const {translations} = useContext(LanguageContext);

    const {data, isLoading} = useQuery({
        queryKey: ["getabcved", excelId],
        queryFn: () => getABCVED(excelId),
    });

    const columns = [
        {
            field: "abcved_id",
            headerName: "ID",
            minWidth: 50,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "supplier_name",
            headerName: translations.poslovodjaProizvodiSupplierName,
            minWidth: 150,
            align: "center",
            headerAlign: "center",
            flex: 1,
        },
        {
            field: "code",
            headerName: translations["adminAbcVedPageProductCode"],
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: translations["adminAbcVedPageProductName"],
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "xyz",
            headerName: translations["adminAbcVedPageXYZ"],
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "abc",
            headerName: translations["adminAbcVedPageABC"],
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "ved",
            headerName: translations["adminAbcVedPageVED"],
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
    ];

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}`}
                            fullWidth
                        >
                            <OnlinePredictionTwoToneIcon sx={{mr: 1}}/>
                            {translations["adminAbcVedPageForecast"]}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/analiza`}
                            fullWidth
                        >
                            <LeaderboardIcon sx={{mr: 1}}/>
                            {translations["adminAbcVedPageAnalysis"]}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item lg={4} sm={4} xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <Button
                            variant="contained"
                            size="large"
                            href={`/administrator/prodavnica/${storeId}/informacije/${excelId}/nabavka`}
                            fullWidth
                        >
                            <InventoryIcon sx={{mr: 1}}/>
                            {translations["adminAbcVedPageAcquisition"]}
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        <UpdateButtons
                            calc_id={excelId}
                            store_code={storeId}
                            success={success}
                            setSuccess={setSuccess}
                            error={error}
                            setError={setError}
                        />
                    </Paper>
                </Grid>
                {success == "" ? null : (
                    <Grid item xs={12}>
                        <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                            <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                                {success}
                            </Alert>
                        </Paper>
                    </Grid>
                )}
                {error == "" ? null : (
                    <Grid item xs={12}>
                        <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                            <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                                {error}
                            </Alert>
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: "flex", flexDirection: "column"}}>
                        {
                            data?.data.length == 0 || data?.data == undefined || data?.data == null ? (
                                <Alert severity="warning">{translations?.messageIfEmptyData}</Alert>
                            ) : (
                                <div style={{height: 600, width: "100%"}}>
                                    {isLoading ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignContent: "center",
                                            }}
                                        >
                                            <CircularProgress/>
                                        </div>
                                    ) : (
                                        <DataGrid
                                            rows={isLoading ? [] : data.data}
                                            columns={columns}
                                            slots={{toolbar: GridToolbar}}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {page: 0, pageSize: 25},
                                                },
                                            }}
                                            localeText={tableLang}
                                            pageSizeOptions={[25, 50, 100]}
                                            disableRowSelectionOnClick
                                        />
                                    )}
                                </div>
                            )
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AbcVedPage;
