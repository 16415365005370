import React, {useContext, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Alert, Button, CircularProgress, Container, Grid, Paper} from "@mui/material";
import {getCalculations} from "../../api/calculationApi";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {useTableLang} from "../../utils/tableLang";
import {getCreatedOrder} from "../../api/orderApi";
import {useNavigate} from "react-router";
import {LanguageContext} from "../../components/LanguageContext";

const PoslovodjaPocetna = () => {
    const tableLang = useTableLang();
    const {translations} = useContext(LanguageContext) || {};
    const [openModal, setOpenModal] = useState(false);
    const [excel, setExcel] = useState(null);
    const [excelError, setExcelError] = useState("");

    const [mutationMessage, setMutationMessage] = useState("");
    const [mutationError, setMutationError] = useState("");

    const queryClient = useQueryClient();

    const storeId = localStorage.getItem('store_id');
    const storeCode = localStorage.getItem('store_code');

    const navigate = useNavigate();

    const columns = [
        {
            field: "id",
            headerName: translations.poslovodjaPocetnaID,
            width: 70,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "file_name",
            headerName: translations.poslovodjaPocetnaFileName,
            minWidth: 300,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "s_name",
            headerName: translations.poslovodjaPocetnaStoreName,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "date_time",
            headerName: translations.poslovodjaPocetnaDateTime,
            minWidth: 200,
            flex: 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "info",
            headerName: translations.poslovodjaPocetnaInfo,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        href={`/poslovodja/prodavnica/${storeCode}/informacije/${params.row.id}/nabavka`}
                    >
                        {translations.poslovodjaPocetnaInfo}
                    </Button>
                );
            },
        },
        {
            field: "orders",
            headerName: translations.poslovodjaPocetnaOrders,
            minWidth: 150,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        href={`/poslovodja/narudzbine/kalkulacija/${params.row.id}`}
                    >
                        {translations.poslovodjaPocetnaOrders}
                    </Button>
                );
            },
        },
    ];

    const {data: calculations, isLoading: isLoadingCalculations} = useQuery({
        queryKey: ["calculations", storeCode],
        queryFn: () => getCalculations(storeCode),
    });

    const orderMutation = useMutation({
        mutationFn: (calculationId) => getCreatedOrder(calculationId),
        onSuccess: (response) => {
            if (response.data.message == 1) {
                setMutationMessage(translations.poslovodjaPocetnaMutationSuccess + ": " + response.data.message_response);
                setMutationError("");
                setTimeout(() => {
                    navigate('../narudžbenica/' + response.data.id);
                }, 2500);
            } else {
                setMutationMessage("");
                setMutationError(response.data.message_response);
            }
        },
        onError: (error) => {
            setMutationMessage("");
            setMutationError(error.response.data.message_response);
        },
    });

    const submitOrderMutation = (e, calcId) => {
        e.preventDefault();
        orderMutation.mutateAsync(calcId).catch((e) => console.log(e));
    }

    return (
        <Container maxWidth="xxl" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{p: 5, display: "flex", flexDirection: "column"}}>
                        {mutationError && (
                            <Alert severity="error" sx={{mb: 2, justifyContent: "center"}}>
                                {translations.poslovodjaPocetnaMutationError}: {mutationError}
                            </Alert>
                        )}
                        {mutationMessage && (
                            <Alert severity="success" sx={{mb: 2, justifyContent: "center"}}>
                                {mutationMessage}
                            </Alert>
                        )}
                        <div style={{height: 600, width: "100%"}}>
                            {isLoadingCalculations ? (
                                <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <DataGrid
                                    rows={isLoadingCalculations ? [] : calculations.data}
                                    slots={{toolbar: GridToolbar}}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 25},
                                        },
                                    }}
                                    localeText={tableLang}
                                    pageSizeOptions={[25, 50, 100]}
                                    disableRowSelectionOnClick
                                />
                            )}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PoslovodjaPocetna;
